.category-text {
    text-align: center;
    font-weight: bold;
    font-size: 20   px;
}


.stat-card-icon {
    font-size: 20px !important; /* Adjust the size as needed */
    min-width: 20px !important;
    width: 50px !important;
    height: 50px !important;
}
