.autocomplete-dropdown {
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: absolute;
    width: 20%;
    z-index: 1000;
}

.autocomplete-dropdown li {
    padding: 10px;
    list-style-type: none;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.autocomplete-dropdown li:last-child {
    border-bottom: none;
}

.autocomplete-dropdown li:hover {
    background-color: #f7f7f7;
}
