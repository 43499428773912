body {
  overflow-y: hidden !important;
}

.container-fluid{
  overflow-y: hidden !important;
  position: relative;
  min-height: 100vh !important; 
  overflow-y: auto; /* Enables vertical scrolling if the content overflows */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.content-body{
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  min-height: 90vh !important;
  height: 90vh !important;
}

