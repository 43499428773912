/* CustomDatePicker.module.css */
.customDatePicker {
    width: 100%;
    background-color:#5b5289;
    color: #fff;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

div.react-datepicker-wrapper{
    width: 100% !important;
}

/* You can add more styles targeting internal elements of react-datepicker */
.react-datepicker__day--selected {
    background-color: #4a90e2;
    color: white;
}

.react-datepicker__header {
    background-color: #f0f0f0;
}
