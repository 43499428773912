.custom-progress-bar .progress-values {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.custom-progress-bar .progress-current {
    position: relative;
}


.custom-progress-bar .progress-title {
    text-align: center;
    margin-top: 10px; /* Adjust as needed */
    font-size: 16px; /* Adjust as needed */
    font-weight: bold; /* Optional */
}

.custom-progress-bar .progress-values {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.progress-available {
    position: absolute;
}

.progress-available-marker {
    background-color: green; /* Your lighter color */
    height: 100%;
}

.progress-available-marker {
    position: absolute;
    z-index: 1; /* Higher than the default progress bar */
    /* Other styles */
}

.custom-progress-bar .progress-numbers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.custom-progress-bar .progress-numbers span {
    position: relative; /* Adjust positioning as needed */
    /* Additional styling for the numbers */
}

.custom-progress-bar .progress-bar {
    background-color: transparent; /* Remove default background */
    position: relative;
}
