.add-investment-btn{
    height: 42px;
}

.investment-stat{
    width: 10%;
}

.investments-tab.tab-content {
    overflow-y: auto;
    position: relative !important;
    margin: 0 !important;
    padding: 0 !important;
    flex-grow: 1;
    display: grid;
    grid-template-rows: auto 1fr auto; /* Adjust based on your layout needs */
    min-height: 50vh;
    max-height: 50vh;
  }